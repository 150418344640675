<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="QuayLai()"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center" :style="'opacity: 1'">
                            CHỌN NGÀY CHẠY VỀ
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-magnify"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div class="row px-2 mt-3">
                <div class="xs12 font-16 font-medium color-success">
                    Xuất phát từ:
                    {{
                        KyLenhTuyChon_ThongTinChung &&
                        KyLenhTuyChon_ThongTinChung.DanhSachLoTrinh
                            ? KyLenhTuyChon_ThongTinChung.DanhSachLoTrinh[1]?.TenBenXe
                            : ""
                    }}
                </div>
            </div>
            <div class="row mt-2 px-2">
                <div class="xs6 mr-2">
                    <DxButton
                        text="Tất cả dòng chẵn"
                        type="default"
                        styling-mode="outlined"
                        width="100%"
                        @click="ChonNgay({ isSoLe: false })"
                    />
                </div>
                <div class="xs6 ml-2">
                    <DxButton
                        text="Tất cả dòng lẻ"
                        type="success"
                        styling-mode="outlined"
                        width="100%"
                        @click="ChonNgay({ isSoLe: true })"
                    />
                </div>
            </div>
            <div class="row mb-4">
                <div class="xs12">
                    <DxList
                        :dataSource="KyLenhTuyChon_DanhSachNgayKyLenh"
                        height="calc(100vh - 168px)"
                        page-load-mode="nextButton"
                        :hoverStateEnabled="false"
                        :focusStateEnabled="false"
                        :activeStateEnabled="false"
                        no-data-text="Không có dữ liệu!"
                        class="list-lenh-style"
                        :show-selection-controls="true"
                        :selection-mode="selectionMode"
                        :select-all-mode="selectAllMode"
                        v-model:selected-item-keys="tmpDanhSachNgayKyLenhDangChonChieuVe"
                    >
                        <template #item="{ data: item }">
                            <div>
                                <FrameVue :item="item" />
                            </div>
                        </template>
                    </DxList>
                </div>
            </div>

            <div class="button-bottom row justify-space-between align-center">
                <DxButton
                    text="Hủy"
                    type="danger"
                    styling-mode="text"
                    @click="QuayLai()"
                />
                <DxButton
                    text="Lưu"
                    type="default"
                    styling-mode="contained"
                    @click="Luu()"
                />
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { DxButton, DxList } from "devextreme-vue";
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";
import { onIonViewWillEnter } from "@ionic/vue";
import FrameVue from "./components/Frame.vue";

export default {
    components: {
        DxList,
        DxButton,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        FrameVue,
    },
    data() {
        return {
            tmpDanhSachNgayKyLenhDangChonChieuVe: [],
            selectionMode: "all",
            selectAllMode: "allPages",
        };
    },
    computed: {
        KyLenhTuyChon_ThongTinChung: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_ThongTinChung || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinChung",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachNgayKyLenh: {
            get() {
                return (
                    this.$store.state.LenhTuyChon.KyLenhTuyChon_DanhSachNgayKyLenh || []
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachNgayKyLenh",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe: {
            get() {
                return (
                    this.$store.state.LenhTuyChon
                        .KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe || []
                );
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe",
                    data: data,
                });
            },
        },
        maxDate() {
            return this.$store.state.DVVTLenh.maxDate;
        },
    },
    watch: {},
    methods: {
        ChonNgay({ isSoLe }) {
            isSoLe = isSoLe ? 1 : 0;

            let DanhSachDongNguocLaiDangChon = [];
            let TatCaDong_TrongDanhSach = this.KyLenhTuyChon_DanhSachNgayKyLenh.filter(
                ({ STT }) => STT % 2 == isSoLe,
            );
            let TatCaDong_DangChon = this.tmpDanhSachNgayKyLenhDangChonChieuVe.filter(
                ({ STT }) => STT % 2 == isSoLe,
            );

            let DanhSachDongSeChon = [];

            if (TatCaDong_DangChon.length == TatCaDong_TrongDanhSach.length) {
                DanhSachDongSeChon = this.tmpDanhSachNgayKyLenhDangChonChieuVe.filter(
                    ({ STT }) => STT % 2 != isSoLe,
                );
            } else {
                DanhSachDongNguocLaiDangChon = this.tmpDanhSachNgayKyLenhDangChonChieuVe.filter(
                    ({ STT }) => STT % 2 != isSoLe,
                );
                DanhSachDongSeChon = this.KyLenhTuyChon_DanhSachNgayKyLenh.filter(
                    ({ STT }) => STT % 2 == isSoLe,
                );
            }

            this.tmpDanhSachNgayKyLenhDangChonChieuVe = [
                ...DanhSachDongNguocLaiDangChon,
                ...DanhSachDongSeChon,
            ];
        },
        QuayLai() {
            this.$router.go(-1);
        },
        Luu() {
            this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe = this.tmpDanhSachNgayKyLenhDangChonChieuVe.sort(
                function (a, b) {
                    return a.STT - b.STT;
                },
            );
            this.$router.go(-1);
        },
    },
    created() {
        onIonViewWillEnter(async () => {
            this.tmpDanhSachNgayKyLenhDangChonChieuVe = this.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe;
        });
    },
    mounted() {},
};
</script>
<style scoped></style>
